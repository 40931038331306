import React, { createContext, useState } from 'react';

export const LikedPhotosContext = createContext();

export const LikedPhotosProvider = ({ children }) => {

  const [likedPhotos, setLikedPhotos] = useState([
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image11.webp', alt: 'トラ子', id: 'user11' },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image12.webp', alt: '美月', id: 'user12' }
  ]);

  const addLikedPhoto = (photo) => {
    setLikedPhotos((prevLikedPhotos) => [...prevLikedPhotos, photo]);
  };
  

  return (
    <LikedPhotosContext.Provider value={{ likedPhotos, addLikedPhoto }}>
      {children}
    </LikedPhotosContext.Provider>
  );
};

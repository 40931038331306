import React, { useState } from 'react';
import './BankApp.css';

const BankApp = ({ setCurrentApp, handleHomeClick }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);

  const handleClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      handleHomeClick();
      setCurrentApp(null);
      setIsAnimating(false);
    }, 300);
  };

  const handleButtonClick = () => {
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 2000);
  };

  const handleDetailClick = () => {
    setShowTransactionDetails(true);
  };

  const closeDetailPopup = () => {
    setShowTransactionDetails(false);
  };


  // 適当なデータを生成
  const transactions = [
      { date: '2024/11/13', description: '口座振替/RTS パイパイ', amount: -1000 },
      { date: '2024/11/12', description: '振込1/ｻｶｲ タイセイ', amount: 70000 },
      { date: '2024/11/09', description: '口座振替/RTS ササクカード', amount: -8500 },
      { date: '2024/11/05', description: '振込B1/スサ タロウ', amount: 1709 },
      { date: '2024/11/02', description: 'スーパーでの支払い', amount: -2900 },
      { date: '2024/10/31', description: '給与', amount: 10977 },
      { date: '2024/10/28', description: '口座振替/RTS パイパイ', amount: -18798 },
      { date: '2024/10/20', description: '口座振替/タヨタカード', amount: -1500 },
      { date: '2024/10/08', description: '振込B1/イシダ カズヤ', amount: 3900 },
      { date: '2024/09/30', description: 'コンビニでの支払い', amount: -420 },
      { date: '2024/09/12', description: '口座振替/ナナペイド', amount: -7200 },
      { date: '2024/08/05', description: '振込D4/ササイ ヒロシ', amount: 25000 },
      { date: '2024/07/15', description: '口座振替/NTM ビザカード', amount: -9900 },
      { date: '2024/06/30', description: 'スーパーマーケットでの支払い', amount: -3400 },
      { date: '2024/05/10', description: 'ATM引き出し', amount: -15000 },
      { date: '2024/03/18', description: '振込C3/フカイ ケンタ', amount: 1500 },
      { date: '2024/03/03', description: '口座振替/サンクスカード', amount: -12300 },
      { date: '2024/02/10', description: '給与', amount: 10250 },
      { date: '2024/01/15', description: '口座振替/RTS ミニストカード', amount: -8000 },
      { date: '2023/12/22', description: '振込A2/ナカジ マサル', amount: 12000 },
      { date: '2023/10/10', description: '飲食店支払い', amount: -3000 },
      { date: '2023/09/11', description: '口座振替/ヨメポスト', amount: -15000 },
      { date: '2023/07/05', description: 'コンビニ入金', amount: 5000 },
      { date: '2023/05/20', description: '口座振替/スルパスカード', amount: -4000 },
      { date: '2023/04/15', description: 'スーパーでの支払い', amount: -5000 },
      { date: '2023/04/10', description: 'ATM引き出し', amount: -20000 }  
  ];

  return (
    <div className={`BankApp-container ${isAnimating ? 'BankHomeback' : ''}`}>
      <div className="bank-header">
        <h1>MUFG銀行</h1>
      </div>
      <div className="bank-body">
        <div className="account-summary">
          <h2>城山 大賀様</h2>
          <p>青阿台支店 普通 17364654</p>
          <h1>14,321 円</h1>
          <button className="detail-button" onClick={handleDetailClick}>明細</button>
        </div>
        <div className="transaction-buttons">
          <button onClick={handleButtonClick}>振込・振替</button>
          <button onClick={handleButtonClick}>ペイジー</button>
          <button onClick={handleButtonClick}>各種手続</button>
          <button onClick={handleButtonClick}>投資信託</button>
          <button onClick={handleButtonClick}>外貨預金</button>
          <button onClick={handleButtonClick}>定期預金</button>
          <button onClick={handleButtonClick}>ワンタイムパスワード</button>
          <button onClick={handleButtonClick}>カードローン</button>
          <button onClick={handleButtonClick}>住宅ローン</button>
          <button onClick={handleButtonClick}>デビットカード</button>
          <button onClick={handleButtonClick}>クレジットカード</button>
        </div>
      </div>
      <div className="bank-footer">
        <button className="home-button" onPointerDown={handleClick}></button>
      </div>
      {showPopup && <div className="popup">システムトラブル中です</div>}

      {/* 明細ポップアップ */}
      {showTransactionDetails && (
        <div className="transaction-popup">
          <button className="close-button" onClick={closeDetailPopup}>×</button>
          <h2>入出金明細</h2>
          <div className="transaction-list">
            {transactions.map((transaction, index) => (
              <div key={index} className="transaction-item">
                <p>{transaction.date}</p>
                <p>{transaction.description}</p>
                <p className={transaction.amount < 0 ? 'negative' : 'positive'}>
                  {transaction.amount.toLocaleString()} 円
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BankApp;
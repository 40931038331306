import React from 'react';
import './UserList.css';

const defaultUsers = [
  { id: 'user1', name: 'ssk', alt: 'User 1', src: '/images/user1.jpg' },
  { id: 'user2', name: 'abc', alt: 'User 2', src: '/images/user2.jpg' }
];

const UserList = ({ users = defaultUsers, selectUser, isUnderReview }) => {
  const uniqueUsers = users.filter((user, index, self) => 
    index === self.findIndex((u) => (
      u.id === user.id
    ))
  );

  return (
    <div className="user-list-container">
      <h2 className="match-title">マッチした相手</h2>
      <ul className="user-list">
        {uniqueUsers.length > 0 ? (
          uniqueUsers.map((user, index) => (
            <li key={index} className="user-item" onClick={() => selectUser(user)}>
              {user.src ? (
                <>
                  <img src={user.src} alt={user.alt} className="user-photo" />
                  <p>{user.alt}</p>
                </>
              ) : (
                user.name
              )}
            </li>
          ))
        ) : (
          <p>まだ誰ともマッチしてないよ。スワイプしよう</p>
        )}
      </ul>      
    </div>
  );
};

export default UserList;

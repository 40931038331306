import React, { useEffect, useState } from 'react';
import './Match.css';
import MatchSwipe from './MatchApp/Match-swipe';
import MatchID from './MatchApp/Match-ID';
import MatchChat from './MatchApp/Match-chat';

const Match = ({ setCurrentApp, handleHomeClick }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  // Match.jsが読み込まれた後、3秒後にcurrentAppを'match-swipe'に設定
  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentApp('match-swipe');
    }, 300);

    // クリーンアップ関数でタイマーを解除
    return () => clearTimeout(timer);
  }, [setCurrentApp]);

  const handleHome = () => {
    setIsAnimating(true);
    setTimeout(() => {
      handleHomeClick(); // App.jsのhandleHomeClickを呼び出し
      setIsAnimating(false);
    }, 300); // アニメーション完了後にisAnimatingをfalseに設定
  };

  return (
    <div className={`match-container ${isAnimating ? 'home-back' : ''}`}>
      {/* match-swipeが3秒後に表示される */}
      <MatchSwipe setCurrentApp={setCurrentApp} handleHomeClick={handleHome} />
      <MatchID setCurrentApp={setCurrentApp} handleHomeClick={handleHome} />
      <MatchChat setCurrentApp={setCurrentApp} handleHomeClick={handleHome} />
    </div>
  );
};

export default Match;

import React, { createContext, useState, useEffect } from 'react';

export const MessageContext = createContext();
export const ResponseContext = createContext();

//Underでのメッセージやり取り
export const UnderMessageProvider = ({ children }) => {
  const [messagesByUser, setMessagesByUser] = useState({
    user11: [
      { text: "こんにちは！マッチありがとう😊", user: true, date: "2024/01/10" },
      { text: "こちらこそ！こちらも嬉しいです！", user: false, bot: true, date: "2024/01/10" },
      { text: "今度どこかでご飯でもどう？", user: true, date: "2024/01/10" },
      { text: "いいですね！友達も誘って2:2でどうかな？", user: false, bot: true, date: "2024/01/11" },
      { text: "いいね！2:2って気楽で楽しいよね！じゃあ、友達に声かけとく～！", user: true, date: "2024/01/11" },
      { text: "了解です！日程とか決まったら教えてね✨", user: false, bot: true, date: "2024/01/12" },
      { text: "友達一人つかまったんだけど、来週月曜の夜とか都合どう？", user: true, date: "2024/01/13" },
      { text: "OKです！場所とか決めとく？", user: false, bot: true, date: "2024/01/13" },
      { text: "澁谷駅近くでいいかな？適当に居酒屋とかで。", user: true, date: "2024/01/13" },
      { text: "いいね！じゃあ当日よろしくー！", user: false, bot: true, date: "2024/01/14" },
      { text: "おはよう！ごめん、急なんだけど友達が急用で来れなくなっちゃた…", user: true, date: "2024/01/15" },
      { text: "あら、そうなんだ！1人でも大丈夫だけど、気まずくない？", user: false, bot: true, date: "2024/01/15" },
      { text: "俺は全然大丈夫！もしよかったらそのまま2:1で会おう！", user: true, date: "2024/01/15" },
      { text: "OK！じゃあ、3人で会おう！楽しみにしてるね😊", user: false, bot: true, date: "2024/01/15" }
    ],
    user12: [
      { text: "美月です、よろしくお願いします！", user: false, bot: true ,date: "2024/01/13" },
      { text: "よろしく！", user: true ,date: "2024/01/13"},
      { text: "今日は天気がいいね。", user: false, bot: true ,date: "2024/01/13"}
    ]
  });

  const setMessagesForUser = (userId, newMessages) => {
    setMessagesByUser((prevMessagesByUser) => ({
      ...prevMessagesByUser,
      [userId]: newMessages,
    }));
  };

  return (
    <MessageContext.Provider value={{ messagesByUser, setMessagesForUser }}>
      {children}
    </MessageContext.Provider>
  );
};


//テキストコンソールへの返答
export const ResponseProvider = ({ children }) => {
  const [responseList] = useState([
    "こんにちは！今日はどう？",
    "何か質問はある？",
    "助けが必要なら教えてね。",
    "元気そうだね！",
    "他に話したいことがあればどうぞ。",
  ]);

  const [responseIndex, setResponseIndex] = useState(0);

  const getNextResponse = () => {
    const response = responseList[responseIndex];
    setResponseIndex((prevIndex) => (prevIndex + 1) % responseList.length);
    return response;
  };

  useEffect(() => {
    console.log("ResponseProvider initialized", getNextResponse); // ログを追加して確認
  }, []);

  return (
    <ResponseContext.Provider value={{ getNextResponse }}>
      {children}
    </ResponseContext.Provider>
  );
};

// textconsoleの対応表
export const responseTable = {
  "こんにちは": "はじめましてよろしく",
  "ありがとう": "どういたしまして！",
  "おはよう": "おはようございます！",
  "ヒント":"何のヒントがほしいですか？",
  "問題1のヒント": "まずは基本を確認してください。",
  "クイズのヒント": "正解は2番目に見つかるはずです。",
  "暗号のヒント": "最初の文字をつなげてみましょう。",
  "計算問題のヒント": "式を分解して考えると簡単です。",
};


export const response4Camera = {
  "2023-11-07 14:30": `https://d15k62eom0hyt7.cloudfront.net/360video/360_003.mp4`,
  "2024-11-16 19:10": `${process.env.PUBLIC_URL}/images/初期360カメラ.jpg`,
  "2024-12-01 12:00": "/videos/video2.mp4",
};



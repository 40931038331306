import React, { useState, useContext, useEffect, useRef } from 'react';
import './Match-swipe.css';
import { LikedPhotosContext } from './LikedPhotosContext.js';
import { PhotoContext } from './PhotoContext.js';

const getRandomPhoto = (excludePhotos, array) => {
  if (array.length === 0) return null;
  let newPhoto;
  do {
    newPhoto = array[Math.floor(Math.random() * array.length)];
  } while (excludePhotos.includes(newPhoto) && excludePhotos.length < array.length);
  return newPhoto;
};

const MatchSwipe = ({ setCurrentApp, handleHomeClick }) => {
  const { addLikedPhoto } = useContext(LikedPhotosContext);
  const { photos, setPhotos } = useContext(PhotoContext);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [nextPhoto, setNextPhoto] = useState(null);
  const [currentAnimationClass, setCurrentAnimationClass] = useState('');
  const [nextAnimationClass, setNextAnimationClass] = useState('');
  const [matchPopup, setMatchPopup] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState('/images/profile.jpg'); // 初期値を設定
  const [isAnimating, setIsAnimating] = useState(false);
  const [canClosePopup, setCanClosePopup] = useState(false); // ポップアップを閉じられる状態を管理
  const [profile, setProfile] = useState(() => JSON.parse(localStorage.getItem('profile')) || {});
  const isFirstRender = useRef(true);
  

  //スワイプ仕様
  useEffect(() => {
    if (photos.length === 0) return; // photosが空のときは何もしない

    if (isFirstRender.current) {
      if (photos.length > 1) {
        const initialPhoto = getRandomPhoto([], photos);
        const nextPhoto = getRandomPhoto([initialPhoto], photos);
        setCurrentPhoto(initialPhoto);
        setNextPhoto(nextPhoto);
      } else if (photos.length === 1) {
        setCurrentPhoto(photos[0]);
        setNextPhoto(null);
      }
      isFirstRender.current = false;
    } else {
      // photosが変更された場合も適切に処理する
      if (photos.length > 1) {
        setNextPhoto(prevNextPhoto => getRandomPhoto([prevNextPhoto, currentPhoto], photos));
      } else if (photos.length === 1) {
        setCurrentPhoto(photos[0]);
        setNextPhoto(null);
      } else {
        setCurrentPhoto(null);
        setNextPhoto(null);
      }
    }
  }, [photos, currentPhoto]);

  
  //スワイプ写真が無くなったときにプロフィール画像を表示する
  useEffect(() => {
    // MatchIDコンポーネントからプロフィール写真を取得
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile && profile.photo) {
      setProfilePhoto(profile.photo);
    }
  }, []);
  

// プロフの写真が特定の写真だった場合、特定の相手とマッチさせる
useEffect(() => {
  const profile = JSON.parse(localStorage.getItem('profile'));

  // 既存のLindaのチェック
  if (profile?.isFromLocalPhotos) {
    const lindaUser = { 
      src: `${process.env.PUBLIC_URL}/images/image40.webp`, 
      alt: 'Linda', 
      id: 'user40', 
      oncontextmenu: "return false;" 
    };
    addLikedPhoto(lindaUser); // Lindaをマッチさせる
  }

  // 新たに追加: isCatfishMatchがtrueならオッジとマッチ
  if (profile?.isCatfishMatch) {
    const oggiUser = { 
      src: `https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/father.webp`, 
      alt: 'オッジ', 
      id: 'user50', 
      oncontextmenu: "return false;" 
    };
    addLikedPhoto(oggiUser); // オッジをマッチさせる
  }
}, []); // 依存配列を空にする 


  const handleSwipe = (direction) => {
    if (currentPhoto === null) return;

    const swipeClass = direction === 'like' ? 'swipe-Like' : 'swipe-Nope';
    setCurrentAnimationClass(swipeClass);
    setNextAnimationClass('zoom-in'); // ズームインアニメーションを設定

    setTimeout(() => {
      if (direction === 'like') {
        // プロフィール条件を取得
        const profile = JSON.parse(localStorage.getItem('profile'));
        const isTall = profile?.height >= 180;
        const isEliteUniversity = ['東大', '早稲田', '慶應義塾'].includes(profile?.university);
  
        // 条件に応じてマッチ確率を調整
        const matchProbability = isTall || isEliteUniversity ? 1 / 4 : 1 / 12; // 条件に合致するとマッチ確率を上げる
  
        if (Math.random() < matchProbability) {
          addLikedPhoto(currentPhoto);
          setMatchPopup(currentPhoto);
        }
  
        const newPhotos = photos.filter(photo => photo !== currentPhoto);
        setPhotos(newPhotos);
      }

      if (photos.length > 1) {
        const newCurrentPhoto = nextPhoto;
        const newNextPhoto = getRandomPhoto([newCurrentPhoto], photos);
        setCurrentPhoto(newCurrentPhoto);
        setNextPhoto(newNextPhoto);
      } else if (photos.length === 1) {
        setCurrentPhoto(nextPhoto);
        setNextPhoto(null);
      } else {
        setCurrentPhoto(null);
        setNextPhoto(null);
      }

      setCurrentAnimationClass('');
      setNextAnimationClass('');
    }, 300);
  };

  const handleLike = () => {
    handleSwipe('like');
  };

  const handleNope = () => {
    handleSwipe('nope');
  };

  const handlePopupClick = () => {
    if (canClosePopup) {
      setMatchPopup(null); // ポップアップを閉じる
    }
  };

  const handleHome = () => {
    setIsAnimating(true);
    setTimeout(() => {
      handleHomeClick();
      setCurrentApp(null);
      setIsAnimating(false);
    }, 300); // Match the duration of the animation
  };

  useEffect(() => {
    if (matchPopup) {
      // アニメーションの終了後にポップアップを閉じられるようにする
      setCanClosePopup(false);
      setTimeout(() => {
        setCanClosePopup(true); // アニメーション後にクリックでポップアップを閉じることを許可
      }, 500); // 500ms後にクリックでポップアップを閉じられるように
    }
  }, [matchPopup]);

  return (
    <div className={`match-swipe-container ${isAnimating ? 'home-back' : ''}`}>
      <div className="match-swipe-header">
        <h1></h1>
      </div>
      <div className="match-swipe-body">
        {currentPhoto !== null ? (
          <div className="swipe-container">
            {nextPhoto !== null && (
              <div className={`photo-container-next ${nextAnimationClass}`}>
                <img
                  src={nextPhoto?.src}
                  alt={nextPhoto?.alt}
                  className="photo-next"
                />
              </div>
            )}
            <div className={`photo-container-current ${currentAnimationClass}`}>
              <img
                src={currentPhoto?.src}
                alt={currentPhoto?.alt}
                className="photo"
              />
            </div>
          </div>
        ) : (
          <div className="no-match-message-container">
            <div className="no-match-Prof-container">
              <img src={profilePhoto} alt="Profile" className="no-match-profile-image" />
              <div className="no-match-ripple"></div>
            </div>
            <p className="no-match-message">近くにマッチできる相手がいません</p>
          </div>
        )}
        <div className="swipe-buttons">
          <button className="nope" onClick={handleNope}></button>
          <button className="like" onClick={handleLike}></button>
        </div>
      </div>
      <div className="match-menu">
        <button className="id-icon match-button" onClick={() => setCurrentApp('match-id')}></button>
        <button className="id-swipe match-button" onClick={() => setCurrentApp('match-swipe')}></button>
        <button className="id-chat match-button" onClick={() => setCurrentApp('match-chat')}></button>
      </div>
      <div className="match-footer">
        <button className="home-button" onPointerDown={handleHome}></button>
      </div>

      {matchPopup && (
        <div className="match-popup" onClick={handlePopupClick}>
          <div className="match-popup-content">
            <h2>MATCH！！！</h2>
            <img src={matchPopup.src} alt={matchPopup.alt} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MatchSwipe;

import React, { useState, useEffect, useContext } from 'react';
import './Match-ID.css';
import { AppContext } from './AppContext.js';
import { LikedPhotosContext } from './LikedPhotosContext.js';

const MatchID = ({ setCurrentApp, handleHomeClick }) => {
  const { setFromMatchChat } = useContext(AppContext);
  const { addLikedPhoto } = useContext(LikedPhotosContext);
  const [editing, setEditing] = useState(false);
  const [profile, setProfile] = useState(() => {
    const savedProfile = localStorage.getItem('profile');
    return savedProfile ? JSON.parse(savedProfile) : {
      name: 'dd',
      age: 26,
      height: 160,
      location: '澁谷区',
      job: '写真家',
      university: '出身',
      photo: '/images/profile.jpg'
    };
  });
  const [isAnimating, setIsAnimating] = useState(false);
  const [showPhotoGrid, setShowPhotoGrid] = useState(false);
  const [photos, setPhotos] = useState([]);

  // ローカルストレージからプロフィール情報を読み込む
  useEffect(() => {
    console.log('Loading profile from local storage');
    fetchPhotos();
  }, []);

  // プロフィール情報をローカルストレージに保存する
  useEffect(() => {
    console.log('Saving profile to local storage:', profile);
    localStorage.setItem('profile', JSON.stringify(profile));
  }, [profile]);

  const fetchPhotos = async () => {
    try {
      console.log('Fetching photos from API');
      const response = await fetch(`https://pixabay.com/api/?key=${process.env.REACT_APP_PIXABAY_API_KEY}&q=landscape&image_type=photo&per_page=30`);
      const data = await response.json();
      const storedPhotos = JSON.parse(localStorage.getItem('photos')) || [];
  
      // catfish画像のリストを追加
      const catfishImages = [
        { id: 'catfish1', previewURL: `${process.env.PUBLIC_URL}/images/catfish/Catfish1.jpg`, date: '2024/10/28' },
        { id: 'catfish2', previewURL: `${process.env.PUBLIC_URL}/images/catfish/Catfish2.jpg`, date: '2024/10/28' },
        { id: 'catfish3', previewURL: `${process.env.PUBLIC_URL}/images/catfish/Catfish3.jpg`, date: '2024/10/28' },
        { id: 'catfish4', previewURL: `${process.env.PUBLIC_URL}/images/catfish/Catfish4.jpg`, date: '2024/10/28' },
        { id: 'catfish5', previewURL: `${process.env.PUBLIC_URL}/images/catfish/Catfish5.jpg`, date: '2024/10/28' }
      ];
  
      setPhotos([...data.hits, ...storedPhotos, ...catfishImages]); // catfish画像も含む
      console.log('Photos fetched:', [...data.hits, ...storedPhotos, ...catfishImages]);
    } catch (error) {
      console.error('Error fetching photos:', error);
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(`Changing profile field: ${name} to ${value}`);
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value
    }));
  };


  //プロフィール画面において特定の写真をクリックした時の挙動　
  const handlePhotoClick = (photo) => {
      console.log('Photo selected:', photo);

      // プロフィール画像を更新する
      const updatedProfile = {
          ...profile,
          photo: photo.largeImageURL || photo.previewURL,
      };

      setProfile(updatedProfile);
      localStorage.setItem('profile', JSON.stringify(updatedProfile)); // ローカルストレージに保存
      setShowPhotoGrid(false);
  };

  //saveボタンを押した時の挙動
  const saveIDToggle = () => {
      console.log('Toggling edit mode. Current state:', editing);

      // フラグの更新や条件の設定を集中管理
      const isFromLocalPhotos = profile.photo && (JSON.parse(localStorage.getItem('photos')) || []).some(
          storedPhoto => storedPhoto.previewURL === profile.photo
      );

      const isCatfishMatch = profile.photo?.includes('Catfish2') && profile.university === '慶應義塾';

      const updatedProfile = {
          ...profile,
          isFromLocalPhotos,
          isCatfishMatch,
      };

      setProfile(updatedProfile);
      localStorage.setItem('profile', JSON.stringify(updatedProfile)); // ローカルストレージに保存
      setEditing(!editing);
  };

 

  const handleSwipeClick = () => {
    console.log('Navigating to match-swipe');
    setCurrentApp('match-swipe');
    setTimeout(() => {
      const matchElement = document.querySelector('.match-swipe-body');
      if (matchElement) {
        matchElement.classList.add('slide-in-L');
        setTimeout(() => {
          matchElement.classList.remove('slide-in-L');
        }, 500);
      }
    }, 0);
  };

  const handleHome = () => {
    console.log('Navigating home');
    setIsAnimating(true);
    setTimeout(() => {
      handleHomeClick();
      setCurrentApp(null);
      setIsAnimating(false);
    }, 300); // Match the duration of the animation
  };

  const handleFileUpload = () => {
    console.log('Displaying photo grid for selection');
    setShowPhotoGrid(true); // アップロードボタンが押されたときに写真欄を表示
  };

  return (
    <div className={`match-id-container ${isAnimating ? 'home-back' : ''}`}>
      <div className="match-id-header">
        <h1></h1>
      </div>
      <div className="match-id-body">
        <img src={profile.photo} alt="Profile" className="profile-image" />
        {editing ? (
          <div className="profile-details">
            <button id="file-upload-button" className="gray-button" onClick={handleFileUpload}>写真を選択</button>
            <input type="text" name="name" value={profile.name} onChange={handleInputChange} />
            <input type="text" name="age" value={profile.age} onChange={handleInputChange} />
            <input type="text" name="height" value={profile.height} onChange={handleInputChange} />
            <input type="text" name="location" value={profile.location} onChange={handleInputChange} />
            <input type="text" name="job" value={profile.job} onChange={handleInputChange} />
            <input type="text" name="university" value={profile.university} onChange={handleInputChange} />
            <button onClick={saveIDToggle}>Save</button>
          </div>
        ) : (
          <div className="profile-details">
            <h2>{profile.name} {profile.age}歳 {profile.height}cm</h2>
            <p>
              <LocationIcon /> {profile.location} 在住
            </p>
            <p>
              <JobIcon /> {profile.job}
            </p>
            <p>
              <UniversityIcon /> {profile.university}大学
            </p>
            <button onClick={saveIDToggle}>Edit</button>
          </div>
        )}
      </div>
      {showPhotoGrid && (
        <div className="photo-grid-ID">
          {photos.map(photo => (
            <div key={photo.id} className="photo-item" onClick={() => handlePhotoClick(photo)}>
              <img src={photo.previewURL} alt={photo.tags} />
            </div>
          ))}
        </div>
      )}
      <div className="match-menu">
         <button className="id-icon match-button" onClick={() => setCurrentApp('match-id')}></button>
         <button className="id-swipe match-button" onClick={handleSwipeClick}></button>
         <button className="id-chat match-button" onClick={() => setCurrentApp('match-chat')}></button>
      </div>
      <div className="match-footer">
         <button className="home-button" onPointerDown={handleHome}></button>
      </div>
    </div>
  );
};

// SVGアイコンを追加
const LocationIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21 10c0 6-9 13-9 13S3 16 3 10a9 9 0 0118 0z"></path>
    <circle cx="12" cy="10" r="3"></circle>
  </svg>
);

const JobIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
    <path d="M16 3H8v4h8V3z"></path>
  </svg>
);

const UniversityIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21 13L12 2 3 13h18z"></path>
    <path d="M5 13v8h14v-8"></path>
    <path d="M10 13v-2h4v2"></path>
  </svg>
);

export default MatchID;
import React, { createContext, useState } from 'react';

export const PhotoContext = createContext();

export const PhotoProvider = ({ children }) => {
  const [photos, setPhotos] = useState([
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image1.webp', alt: 'みき1', id: 'user1', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image2.webp', alt: 'ま2', id: 'user2', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image3.webp', alt: 'aa3', id: 'user3', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image4.webp', alt: 'Risa', id: 'user4', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image5.webp', alt: '神奈川県民', id: 'user5', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image6.webp', alt: 'エリサ', id: 'user6', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image7.webp', alt: 'さやか', id: 'user7', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image8.webp', alt: 'Hikari0821', id: 'user8', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image9.webp', alt: 'あかり', id: 'user9', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image10.webp', alt: 'ユカ', id: 'user10', oncontextmenu: "return false;" },
    // { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image11.webp', alt: 'トラ子', id: 'user11', oncontextmenu: "return false;" },
    // { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image12.webp', alt: '美月', id: 'user12', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image13.webp', alt: '変な名前47', id: 'user13', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image14.webp', alt: 'あおい', id: 'user14', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image15.webp', alt: 'M', id: 'user15', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image16.webp', alt: '限界新卒', id: 'user16', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image17.webp', alt: '奈々', id: 'user17', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image18.webp', alt: 'あさひ', id: 'user18', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image19.webp', alt: 'みきたん', id: 'user19', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image20.webp', alt: 'flower', id: 'user20', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image21.webp', alt: 'さくら', id: 'user21', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image22.webp', alt: 'n', id: 'user22', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image23.webp', alt: '999', id: 'user23', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image24.webp', alt: 'スパゲティ', id: 'user24', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image25.webp', alt: 'りんご', id: 'user25', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image26.webp', alt: 'haruna', id: 'user26', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image27.webp', alt: 'あやか', id: 'user27', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image28.webp', alt: 'たけし', id: 'user28', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image29.webp', alt: 'mana', id: 'user29', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image30.webp', alt: 'みーたん', id: 'user30', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image31.webp', alt: 'はるか', id: 'user31', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image32.webp', alt: 'あかずきん', id: 'user32', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image33.webp', alt: 'みあ卍', id: 'user33', oncontextmenu: "return false;" },
    { src: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/image34.webp', alt: 'ハート222', id: 'user34', oncontextmenu: "return false;" },   
    // { src: `${process.env.PUBLIC_URL}/images/image40.webp`, alt: 'Linda', id: 'user40', oncontextmenu: "return false;" }, 
    
  
    // 追加の写真をここに追加できます
  ]);

  return (
    <PhotoContext.Provider value={{ photos, setPhotos }}>
      {children}
    </PhotoContext.Provider>
  );
};
